<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">TRANSACTIONS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex column gaps mv-10">
        <div class="box grow flex gaps ">
                <el-select
                  v-model="search"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Please select an item"
                  @change="handleSelect"
                  :remote-method="remoteMethod">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            <div class="box grow"></div>
        </div>
        <div class="box" :style="{width: tableWidth + 'px'}">
            <el-table
            :max-height="tableHeight"
            :data="dataTransactions"
            style="width: 100%">
            <el-table-column
                prop="date"
                label="Date"
                width="200">
              <template #default="scope">
                <div>{{getDate(scope.row.date)}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="count"
                label="Count">
            </el-table-column>
            <el-table-column
                prop="price"
                label="Price">
            </el-table-column>
            <el-table-column
                prop="eventType"
                label="EventType">
            </el-table-column>
            <el-table-column
                prop="memo"
                label="Memo">
            </el-table-column>
            </el-table>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'Transaction',

  data () {
    return {
      search: '',
      dataTransactions: [],
      buffer: {
        date: -1,
        text: ''
      },
      options: []
    }
  },

  computed: {
    ...mapState({
      warehouse: state => state.warehouse.warehouse,
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth
    }),
    ...mapGetters([
      'grouped'
    ]),
    tableHeight () {
      return this.innerHeight - 209
    },
    tableWidth () {
      return this.innerWidth - 30
    }
  },

  methods: {
    ...mapActions(['getTransaction']),
    remoteMethod (query) {
      if (query !== '' && query.length >= 2) {
        this.options = this.warehouse.filter(item => {
          return item.name.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        }).map(i => {
          return {
            value: i._id,
            label: i.name
          }
        })
      } else {
        this.options = this.clearOption
      }
    },
    handleSelect (id) {
      this.getTransaction({ id })
        .then(() => {
          this.dataTransactions = this.grouped[id].sort(this.sortFunction)
        })
    },
    getDate (date) {
      let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return new Date(date).toLocaleString('en-GB', options)
    },
    keyDown: function (e) {
      const today = new Date()
      if (this.buffer.text === '') {
        this.buffer.date = today.getTime()
        this.buffer.text = this.buffer.text + '' + e.key
      } else if (e.key === 'ArrowDown' && this.buffer.text.slice(-5) === 'Enter') {
        this.scanBarCode(this.buffer.text.slice(0, -5))
        Object.assign(this.buffer, {
          date: -1,
          text: ''
        })
      } else if (this.buffer.date > today.getTime() - 1000) {
        this.buffer.text = this.buffer.text + '' + e.key
      } else {
        Object.assign(this.buffer, {
          date: today.getTime(),
          text: e.key
        })
      }
    },
    scanBarCode (text) {
      this.search = text
      let item = this.items.find(el => el.barcode.indexOf(text) === 0)
      if (item) {
        this.search = item.name
        this.getTransaction({ id: item._id })
          .then(() => {
            this.dataTransactions = this.grouped[item._id].sort(this.sortFunction)
          })
      }
    },
    sortFunction (a, b) {
      let dateA = new Date(a.date).getTime()
      let dateB = new Date(b.date).getTime()
      return dateA > dateB ? -1 : 1
    }
  },
  mounted () {
    if (this.$route.params.id) {
      let item = this.warehouse.find(el => el._id === this.$route.params.id)
      if (item) {
        this.getTransaction({ id: this.$route.params.id })
          .then(() => {
            this.search = item.name
            this.dataTransactions = this.grouped[this.$route.params.id].sort(this.sortFunction)
          })
      }
    }
  },
  created: function () {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed: function () {
    window.removeEventListener('keydown', this.keyDown)
  },
  watch: {
    '$route.params.id': function (val, oldVal) {
      this.getTransaction({ id: val })
        .then(() => {
          this.dataTransactions = this.grouped[val].sort(this.sortFunction)
        })
    }
  }
}
</script>
